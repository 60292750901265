import {
  SpotifyApi,
  AuthorizationCodeWithPKCEStrategy,
} from "@spotify/web-api-ts-sdk";

class SpotifyService {
  // private static instance: SpotifyApi | null = null;
  private static spotifyScopes = ["playlist-modify-public"];
  private static redirectUrl =
    process.env.REACT_APP_REDIRECT_URI ?? "http://127.0.0.1:3000/callback";
  private static clientId = process.env.REACT_APP_SPOTY_CLIENT_ID ?? "";

  async getClient(): Promise<SpotifyApi | null | undefined> {
    try {
      const getAuthorizationTokens = new AuthorizationCodeWithPKCEStrategy(
        SpotifyService.clientId,
        SpotifyService.redirectUrl,
        SpotifyService.spotifyScopes
      );
      const spotifyInstance = new SpotifyApi(getAuthorizationTokens);
      const getSpotyAuthentication = await spotifyInstance.authenticate();
      if (getSpotyAuthentication) {
        return spotifyInstance;
      }
    } catch (error) {
      console.error("🥮 grim__error:", error);
      return null;
    }
  }
}

export { SpotifyService };
