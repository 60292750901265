import * as React from "react";

import { Divider, Box, Modal, Button, Fade, Container } from "@mui/material";
import Heroes from "@ds-components/Main/dotaselector";
import SpotifyLogin from "@ds-components/Main/spotifylogin";
import Credits from "@ds-components/Main/credits";
import SpotifyTing from "@ds-components/Playlist/playlist";
import { getLocalStorage, getSpotifyDetail } from "@ds-services/helpers";
import { heroSelectorStyles } from "@ds-components/General/dotaspottotheme";
import "../../scss/heroselect.scss";
// import "@ds-scss/heroselect.scss";

export interface PlaylistStatus {
	status?: boolean,
	visible?: boolean

}

const HeroSelect: React.FC<{}> = () => {

	const [spotifyauth, setSpotifyAuth] = React.useState<any | null>({
		status: "checking",
		type: null,
	});

	const spotifyauthentication = (index: any) => {
		const checkAccessToken = getLocalStorage("access_token");
		if (checkAccessToken) {
			setSpotifyAuth({ index })
		}
	};

	const [thehero, setTheHero] = React.useState<any | null>({
		status: "loading",
	});

	const sendDataToGrandParent = (index: any) => {
		setTheHero({
			status: "loaded",
			payload: index,
			action: index.action,
		});
	};

	const [playlistVisible, setPlaylistVisible] = React.useState<PlaylistStatus>({
		status: false,
		visible: false
	});

	const checkPlaylistVisible = (show?: PlaylistStatus) => {
		console.log(`🥮grim__show==> ==> ==>show`);
		console.log({ grim__show: show });
		if (show) {
			setPlaylistVisible(show);
		} else {
			setPlaylistVisible({
				status: false,
				visible: false
			});
		}
		return playlistVisible;
	};

	// console.log(`🥮grim__thehero==> ==> ==>thehero`);
	// console.log({ grim__thehero: thehero });
	// console.log(`🥮grim__playlistStatus==> ==> ==>playlistStatus`);
	// console.log({ grim__playlistStatus: playlistVisible });
	return (
		<Box id="heroSelect"
			sx={heroSelectorStyles}
			className={thehero.payload ? thehero.payload : ""}
		>
			<SpotifyLogin
				spotifyauthentication={spotifyauthentication}
				reminder={thehero.payload}
			/>
			<Box sx={{ display: 'flex', justifyContent: 'center', overflowX: "hidden" }} id="hero-select">
				<Box sx={{
					minHeight: `100vh`
				}}>
					<Container
						sx={{
							paddingTop: 0,
							paddingBottom: 1,
						}}>
						<h1 style={{
							textAlign: "center",
							fontSize: "3rem",
							fontWeight: "bold",
						}}>
							Dota Tunes
						</h1>
					</Container>
					<Heroes sendDataToGrandParent={sendDataToGrandParent} />
					{
						!playlistVisible.visible && playlistVisible.status && (
							<Container sx={{
								marginBottom: 6,
							}}>
								<Button
									variant="contained"
									size="large"
									onClick={() => {
										console.log("check that");
										setPlaylistVisible({
											visible: true,
										});
									}}>Show Playlist</Button>
							</Container>
						)
					}
				</Box>
				{thehero.status === "loaded" && thehero.action === "search" && (
					<SpotifyTing thehero={thehero} checkPlaylistVisible={checkPlaylistVisible} seePlaylistStatus={playlistVisible} />
				)}
			</Box>
			{/* <Divider></Divider> */}
			<Credits />
		</Box>
	);
};
export default HeroSelect;
