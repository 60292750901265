import { SpotifyAccess } from "@ds-types/spotify";

const setLocalStorage = (name: string, value: any) => {
	return localStorage.setItem(name, value);
};

const removeLocalStorage = (name: string) => {
	return localStorage.removeItem(name);
};

const getLocalStorage = (name: string | any) => {
	return localStorage.getItem(name) ? localStorage.getItem(name) : null;
};

const hashValues: any = () => {

	if (
		window.location.hash.includes("#") &&
		window.location.hash.includes("&") &&
		window.location.pathname.includes('callback')
	) {
		const urlObject: SpotifyAccess = {};
		window.location.hash.split("&").forEach((urlhash) => {
			const urlsplit = urlhash.split("=");
			const urlkey = urlsplit[0].replace("#", "");
			const urlvalue = urlsplit[1];
			if (urlkey) {
				urlObject[urlkey] = urlvalue;
			}
		});
		return urlObject;
	} else {
		return false;
	}
};

const getSpotifyDetail = {
	getAuthToken() {
		return getLocalStorage("access_token");
	},
	getUserId() {
		return getLocalStorage("profile_id");
	},
}


export { setLocalStorage, getLocalStorage, removeLocalStorage, hashValues, getSpotifyDetail };
