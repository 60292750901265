import * as React from "react";

import { DotaSpottoTheme, DotaSpottoFullTheme } from "@ds-components/General/dotaspottotheme";
import { checkSpotifyAuth, checkUserProfile } from "@ds-services/spotify/authentication";
import { SpotifyService } from "@ds-services/spotify/SpotifyClient";

// <=== 💸  MUI Stuff  💸 ===>
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
// import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Slide from "@mui/material/Slide";
import { Button, Typography } from "@mui/material";
import { getLocalStorage } from "@ds-services/helpers";
import { SpotifyApi } from "@spotify/web-api-ts-sdk";


// <=== 💸  Theme Settings  💸 ===>
const theme = createTheme(DotaSpottoFullTheme);
const primaryFont = DotaSpottoTheme.primary;
interface LoggedIn {
	active: boolean;
	hero?: any;
	event?: string | null;
}

const loginAlertActive = () => {
	const loginAlertIcon = document.getElementById("login-reminder");
	if (loginAlertIcon) {
		return true;
	} else {
		return false;
	}
};

const SpotifyLogin: React.FC<{
	spotifyauthentication: any;
	reminder: any;
}> = ({ spotifyauthentication, reminder }) => {
	// <=== 💸  START States  💸 ===>
	const [spotifyAuthorization, setSpotifyAuthorization] = React.useState<any | null>({
		status: "checking",
	});
	const [loginalert, setLoginAlert] = React.useState<LoggedIn>({
		active: false,
	});
	const spotifyLoginButton = document.getElementById("spotify-login-link");
	const loginHover = (e: any) => {
		const loginAlertCheck = loginAlertActive();

		if (
			e.target.id === "spotify-login-link" &&
			loginalert.event === "click" &&
			loginAlertCheck
		) {
			setLoginAlert({
				active: false,
				event: e.type,
			});
		}
	};
	const spotifyLoginService = async () => {
		const spotifyService = new SpotifyService();
		const getSpotyInstance = await spotifyService.getClient();
		setSpotifyAuthorization({
			status: "authenticated",
			spotify: getSpotyInstance,
		})
		return getSpotyInstance;
	}
	
	const getUser = async () => {
		if (checkSpotifyAuth() && spotifyAuthorization.status !== "authenticated") {
			const spotifyService = new SpotifyService();
			const getSpotyInstance = await spotifyService.getClient();
			if (getSpotyInstance) {
				setSpotifyAuthorization({
					status: "authenticated",
					spotify: getSpotyInstance,
					userProfile: await checkUserProfile(getSpotyInstance),
				});
			}
		}
	}
	
	React.useEffect(() => {
		if (reminder && spotifyLoginButton) {
			const loginAlertCheckClick = loginAlertActive();
			if (!loginalert.active && !loginAlertCheckClick) {
				setLoginAlert({
					active: true,
					event: reminder.eventType,
				});
			}
		}
		if (spotifyAuthorization.status !== "authenticated") {
			getUser();
		}
		if (spotifyAuthorization.status === "authenticated") {
			spotifyauthentication(spotifyAuthorization);
		}
	}, [spotifyLoginButton, spotifyAuthorization])
	// <=== 💸  END States  💸 ===>
console.log(`🥮grim__spotifyAuthorization==> ==> ==>spotifyAuthorization`);
console.log({grim__spotifyAuthorization: spotifyAuthorization});
	return (
		<AppBar
			position="static"
			enableColorOnDark
			sx={{
				boxShadow: 0,
				bgcolor: 'transparent',
				backgroundImage: 'none',
				// mt: 'calc(var(--template-frame-height, 0px) + 28px)',
			}}
		>
			<Container sx={{
				padding: 1
			}}>
				<ThemeProvider theme={theme}>
					{(!spotifyAuthorization.status ||
						spotifyAuthorization.status !== "authenticated") && (
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-end",
									gap: 2,
								}}
							>
								<Typography sx={{ fontSize: "1.25rem", bgcolor: "transparent" }} component="div" className="static-text">
									Start Building Your Playlist:
								</Typography>
								<Button
									id="spotify-login-link"
									variant="contained"
									onClick={spotifyLoginService}
									onMouseEnter={loginHover}
								>
									Log Into Spotify
								</Button>
								<Slide
									direction="left"
									in={loginalert.active}
									mountOnEnter
									unmountOnExit
									style={{
										display: "inline-flex",
									}}
								>
									<Box>
										<img
											src="/mirana_ohlook.png"
											height={40}
											width={40}
											id="login-reminder"
										/>
									</Box>
								</Slide>
							</Box>
						)}
					{(spotifyAuthorization.status === "authenticated") && (
						<>
							<Box id="spotify-user-profile">
								<Stack direction="row" spacing={1} sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-end",
									gap: 2,
								}}>
									<Chip
										avatar={
											<Avatar
												// sizes="small"
												alt={`${spotifyAuthorization.userProfile.display_name} Portrait`}
												src={spotifyAuthorization.userProfile.images[0]?.url}
											/>
										}
										component="a"
										target="_blank"
										color="primary"
										// sx={{ height: 70 }}
										// size="medium"
										href={spotifyAuthorization.userProfile.external_urls.spotify}
										label={spotifyAuthorization.userProfile.display_name}
										sx={{
											// height: 70,
											"& .MuiChip-label": {
												fontSize: "1.25rem",
												paddingRight: 2,
											},
										}}
										clickable
									/>
								</Stack>
							</Box>
						</>
					)}
				</ThemeProvider>
			</Container>
		</AppBar>
	);
};

export default SpotifyLogin;
