import { AuthorizationCodeWithPKCEStrategy, SpotifyApi, User, AccessToken } from "@spotify/web-api-ts-sdk";

import { SpotifyAccess, SpotifyProfile } from "@ds-types/spotify";
import {
	setLocalStorage,
	getLocalStorage,
	hashValues,
	removeLocalStorage,
} from "../helpers";
import { SpotifyService } from "@ds-services/spotify/SpotifyClient";

const spotyBlob = process.env.REACT_APP_SPOTY_FUNC ?? `/api/spotify`;

const storeSpotyToken = async (token: AccessToken) => {
	const spotifyAuth = await fetch(spotyBlob, {
		"method": "POST",
		"body": JSON.stringify({
			"data": token,
			"type": "check"
		})
	});

	return spotifyAuth;
}

const getSpotyToken = async () => {
	const getStoredToken = fetch(spotyBlob);
	return getStoredToken;
}

const checkUserProfile = async (spotifyClient: SpotifyApi) => {
	// const spotUserProfileEndpoint = "https://api.spotify.com/v1/me";
	// if (token) {
	// 	return await fetch(spotUserProfileEndpoint, {
	// 		method: "GET",
	// 		headers: {
	// 			Authorization: "Bearer " + token,
	// 		},
	// 	})
	// 		.then(async (response) => {
	// 			const checkUserProfileResponse: User = await response.json();
	// 			if (response.ok) {
	// 				const userProfileData: User = checkUserProfileResponse;
	// 				return userProfileData;
	// 			} else {
	// 				// console.log(badResponseHandler(checkUserProfileResponse));
	// 				// console.log(checkUserProfileResponse);
	// 				return Promise.resolve(badResponseHandler(checkUserProfileResponse));
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			// console.log(error);
	// 			throw new Error(error);
	// 		});

	const getCurrentUserProfile = await spotifyClient?.currentUser.profile();
	if (getCurrentUserProfile) {
		// const expirationTime = new Date().getTime() + 3600 * 1000; // 1 hour from now
		setLocalStorage("profile_image", getCurrentUserProfile.images[0].url);
		setLocalStorage("profile_name", getCurrentUserProfile.display_name);
		setLocalStorage("profile_api_href", getCurrentUserProfile.href);
		setLocalStorage("profile_id", getCurrentUserProfile.id);
		return getCurrentUserProfile;
	} else {
		return false;
	}

};
/** === ☠ ☠ ☠ ===>

Handling Spotify Auth Logic

<=== ☠ ☠ ☠ === **/
const checkSpotifyAuth = () => {
	const checkLocalAccessToken = getLocalStorage("access_token");
	const accessTokenExpired = getLocalStorage("expir_access_token");
	if (!checkLocalAccessToken && !accessTokenExpired) return false;
	return true;
};

export { checkSpotifyAuth, checkUserProfile, storeSpotyToken, getSpotyToken };
