import * as React from "react";
import { useCallback, useEffect, useMemo, useRef } from "react";

import useDotaData from "@ds-services/dota/mergedotadata";
import {
	checkSpotifyAuth,
	// userIsLoggedIn,
} from "@ds-services/spotify/authentication";
import { setLocalStorage, getLocalStorage } from "@ds-services/helpers";

// <=== 💸  MUI Stuff  💸 ===>
// import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import { Container, createTheme, ThemeProvider } from "@mui/material";
import { DotaSpottoTheme } from "@ds-components/General/dotaspottotheme";
// import { ThemeProvider } from "@emotion/react";
// import { useEffect, useState } from "react";

import DOMPurify from "dompurify";
import { HeroLookup } from "@ds-types/dotes";
import { HeroDataService } from "@ds-types/responsestate";
import { fontWeight } from "@mui/system";


interface HeroSelectorProps {
	herolist: HeroDataService<HeroLookup[]>;
	sendDataToParent: (data: any) => void;
}

const HeroSelector: React.FC<HeroSelectorProps> = ({ herolist, sendDataToParent }) => {
	// console.log(`🥮grim__data==> ==> ==>data`);
	// console.log({grim__data: sendDataToParent});
	// Compute and sort hero options
	const options = useMemo(() => {
		if (herolist.status !== "loaded") return [];
		if (herolist.status === "loaded" && herolist.payload) {
			// console.log(`🥮grim__herolist==> ==> ==>herolist`);
			// console.log({ grim__herolist: herolist });

			return herolist.payload.map((hero: HeroLookup) => {
				const firstLetter = hero.localized_name[0].toUpperCase();
				return {
					firstLetter: /[a-zA-Z]/.test(firstLetter) ? firstLetter : "0-9",
					...hero,
				};
			})
				.sort((a, b) => a.firstLetter.localeCompare(b.firstLetter));
		}
	}, [herolist]);
	// Set default hero from local storage or fallback to the first hero
	const defaultHero = useMemo(() => {
		const storedHero: HeroLookup | null = getLocalStorage("the_hero") ? JSON.parse(getLocalStorage("the_hero") as string) as HeroLookup : null;
		return storedHero || options?.[0];
	}, [options]);

	const [value, setValue] = React.useState<any | null>(defaultHero);
	const [tempVal, setTempVal] = React.useState<any | null>(value);
	const [inputValue, setInputValue] = React.useState("");
	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		videoRef.current?.load();
	}, [value.video, tempVal]);


	// console.log(`🥮grim__options==> ==> ==>options`);
	// console.log({ grim__options: options });
	/**
	 * Other Funks
	 */
	// Handle button click for hero selection
	const handleHeroUpdate = useCallback(
		async (e: React.MouseEvent<HTMLButtonElement>) => {
			try {
				const currentTargetId = e.currentTarget.id;
				const response = await checkSpotifyAuth();
				// console.log(`🥮grim__response==> ==> ==>response`);
				// console.log({ grim__response: response });
				if (!response) {
					// if (await userIsLoggedIn()) {
					// 	window.location.reload();
					// } else if (e.type === "click") {
					// }
					sendDataToParent({ showReminder: true, eventType: e.type });
				}
				else if (currentTargetId && currentTargetId === "hero-select-button") {
					if (!value["action"]) {
						value["action"] = "search";
					}
					sendDataToParent(value);
				}
			} catch (error) {
				console.error("Error during hero selection update:", error);
			}
		},
		[value, sendDataToParent]
	);
	// const videoPlayer = document.querySelector("#hero-video-bg") as HTMLVideoElement;
	// if(videoPlayer) videoPlayer.playbackRate = 0.75;

	// const dotaSelectorTheme = createTheme({
	// 	components: {
	// 		MuiDataGrid: {
	// 			styleOverrides: {
	// 				root: {
						
	// 				},
				
	// 			},
	// 		},
	// 	},
	// });
	return (
		<Box>
			<Box component="form" sx={{
				display: "flex",
				gap: 3,
				maxWidth: 960,
				margin: "auto",
			}}>
				{/* <ThemeProvider theme={dotaSelectorTheme}></ThemeProvider> */}
				<Autocomplete
					slotProps={{
						paper: {
							sx: {
								'& .MuiAutocomplete-listbox': {
									'& .MuiAutocomplete-option:hover': {
										backgroundColor: "gold",
										color: DotaSpottoTheme.primary,
									},
								},
							},
						},
					}}
					sx={
						(theme) => ({
							flex: 1,
							"& input": {
								fontSize: "1.5rem",
							},
							"& label": {
								bgcolor: "transparent"
							},
						})}
					value={value}
					disableClearable
					autoComplete={true}
					isOptionEqualToValue={(option: any, value: any) =>
						option.id === value.id
					}
					onChange={(event: any, newValue: string | null) => {
						if (newValue) {
							setValue(newValue);
							setLocalStorage("the_hero", JSON.stringify(newValue));
						}
					}}
					inputValue={inputValue}
					onInputChange={(event, newInputValue) => {
						if (newInputValue) {
							setInputValue(newInputValue);
							const formattedInput = newInputValue.toLowerCase().replace(/ /g, "_");
							sendDataToParent(formattedInput);
						}
					}}
					// className={value}
					options={options ?? []}
					groupBy={(option: any) => option.firstLetter}
					getOptionLabel={(option: any) => option.displayName}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Select Your Hero"
							className="dota-gas"
							variant="outlined"
							fullWidth
							sx={{
								backgroundColor: `rgba(225,225,225,0.25)`,
								borderRadius: `6px`
							}}
						/>
					)}
					renderOption={(props, option) => {
						return (
							<ListItem
								slotProps={{}}
								{...props}
								key={option.localized_name}
								disablePadding
								onMouseEnter={(e) => {
									const hoverHeroName = (e.target as HTMLElement).innerText;
									const hoveredHero = options?.find(option => option.displayName === hoverHeroName);
									if (hoveredHero && hoveredHero?.video) {
										if (!document.getElementById(`${hoverHeroName}hero-video-prefetch`)) {
											const videoPrefetch = document.createElement('link');
											videoPrefetch.id = `${hoverHeroName}hero-video-prefetch`;
											videoPrefetch.rel = 'prefetch';
											videoPrefetch.href = `https://cdn.dota2.com${hoveredHero.video}`;
											document.head.appendChild(videoPrefetch);
										}
										setTempVal(hoveredHero);
									}
								}}
								onMouseLeave={() => {
									setTempVal(null);
								}}
							>
								<Avatar
									src={`https://cdn.dota2.com${option.icon}`}
									className={`heroface`}
									sx={{ mr: 2 }}
								/>
								<React.Fragment>
									<Box>
										<span>{option.displayName}</span>
									</Box>
								</React.Fragment>
							</ListItem>
						)
					}}
				// render
				/>
				
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-end",
					}}
				>
					<Button
						variant="contained"
						size="large"
						id="hero-select-button"
						// sx={{
						// 	height: "100%",
						// }}
						onClick={handleHeroUpdate}
					>
						Hero Selection
					</Button>
				</Box>
			</Box>
			<br />
			<div>
				<>
					{value && value.bio ? (
						<Box>
							<Box sx={{
								"@keyframes backgroundSlide": {
									"100%": {
										// backgroundPositionX: "calc(256px / 6)",
										backgroundPositionX: "256px",
										// backgroundPositionY: "calc(144px / 4)",
									}
								},
								backgroundImage: `url(https://cdn.dota2.com${value.img})`,
								backgroundRepeat: "repeat",
								backgroundBlendMode: "overlay",
								background: "none",
								animation: "backgroundSlide 10s linear infinite",
								height: 144,
								width: "100vw",
								position: "relative",
								// width: "100%",
							}}>
								<Box sx={{
									fontSize: "3rem",
									alignItems: "center",
									justifyContent: "center",
									display: "flex",
									height: "100%",
									// backgroundColor: "rgba(0, 0, 0, 0.36)",
									// background: "none",
									"& h2": {
										textShadow: "0 3px 6px black",
										display: "flex",
										flexFlow: "column nowrap",
										lineHeight: "1",
										zIndex: 1,
									},
									"& h2:after": {
										content: "''",
										// position: "absolute",
										width: "100%",
										height: "8px",
										marginTop: "-11px",
										background: DotaSpottoTheme.primary,
										zIndex: -1,
									}
								}}
								>
									<h2>
										{tempVal ? tempVal.displayName : value.displayName}
									</h2>
								</Box>
							</Box>
							{/* <img
								style={{
									width: 256,
									height: 144,
								}}
								alt={`${value.displayName} Portrait`}
								src={`https://cdn.dota2.com${value.img}`}
							/> */}
							<Container>
								{value.video && (
									<Box
										id={"hero-video-bg-container"}
										sx={{
											position: "fixed",
											zIndex: -1,
											width: "100vw",
											height: "290vh",
											top: "-30vh",
											left: "0",
											display: "flex",
											justifyContent: "center",
											opacity: 0.35,
											filter: "saturate(11) blur(10px) contrast(1.2)",
											// filter: "blur(10px) ",
										}}>
										<video
											autoPlay
											loop
											muted
											style={{
												objectFit: "contain",
												flex: "1",
												height: "auto"
											}}
											ref={videoRef}
											// key={value.video}
											id="hero-video-bg"
											poster={`https://cdn.dota2.com${(tempVal ? tempVal.video : value.video).replace("webm", "png")}`}
										>
											<source
												src={`https://cdn.dota2.com${tempVal ? tempVal.video : value.video}`}
												type="video/webm"
											/>
											Your browser does not support the video tag.
										</video>
									</Box>
								)}

								<p
									style={{ fontWeight: "bold" }}
									dangerouslySetInnerHTML={{
										__html: DOMPurify.sanitize(
											(tempVal ? tempVal.bio : value.bio).replace(/\\/g, '')
										)
									}}></p>
							</Container>
						</Box>
					) : (
						<Skeleton
							sx={{ bgcolor: "#FFF" }}
							variant="rectangular"
							animation="wave"
							width={256}
							height={144}
						/>
					)}
				</>
			</div>
		</Box>
	);
};

// const Heroes: React.FC<{ sendDataToGrandParent: () => void }> = ({
const Heroes: React.FC<{ sendDataToGrandParent: (index: number) => void }> = ({
	sendDataToGrandParent
}) => {
	const dotaData = useDotaData();

	// if (!heroData) return <div>No data available</div>
	if (dotaData.status === "error") {
		// console.error(`🥮grim__dotaselectorError==> ==> ==>dotaselectorError`);
		// console.error({ grim__dotaselectorError: dotaData.error });
	}
	return (
		<Box
			sx={{
				flexGrow: 1
			}}
		>
			{dotaData.status === "loading" || dotaData.status === "init" && <Container>Loading...</Container>}
			{dotaData.status === "error" && <div>Error: Error Dawg. Check console logs.</div>}
			{dotaData.status === "loaded" &&
				<HeroSelector
					herolist={dotaData}
					sendDataToParent={sendDataToGrandParent}
				/>}
			<br />
		</Box>
	);
};
export default Heroes;
