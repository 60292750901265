import React, { useEffect } from "react";
import { SpotifyService } from "@ds-services/spotify/SpotifyClient";
import { SpotifyApi } from "@spotify/web-api-ts-sdk";
import type { AccessToken } from "@spotify/web-api-ts-sdk";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setLocalStorage } from "../services/helpers";
import { checkUserProfile, storeSpotyToken } from "@ds-services/spotify/authentication";

const SpotifyCallback: React.FC = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	useEffect(() => {
		const handleAuthCallback = async () => {
			const accessCode = searchParams.get("code");
			const storedToken = localStorage.getItem("access_token");
			if (!storedToken && accessCode) {
				console.log(`🥮grim__accessCode==> ==> ==>accessCode`);
				console.log({ grim__accessCode: accessCode });
				// Store the token
				setLocalStorage("access_code", accessCode);
				const spotifyService = new SpotifyService();
				const getSpotyInstance = await spotifyService.getClient();
				await getSpotyInstance?.getAccessToken()?.then((token) => {
					if (token) {
						storeSpotyToken(token);
						setLocalStorage("access_token", token.access_token);
						setLocalStorage("refresh_token", token.refresh_token);
						setLocalStorage("expir_access_token", Date.now() + (token.expires_in * 1000));
						checkUserProfile(getSpotyInstance);
						navigate("/");
					}
				});
			} else if (storedToken) {
				// navigate("/");
			} else {
				// Handle error or failed authentication
				console.error("No access token found in the callback URL.");
			}
		}
		handleAuthCallback();
	}, []);

	return (
		<div>
			<p>Authenticating...</p>
		</div>
	);
};

export default SpotifyCallback;
